.carousel-container {
  padding-left: 70px;
  /* margin-top: 25px; */
  margin-bottom: 25px;
}

.CarouselItem {
  width: 100%;
  height: 100%;
}
.cardTitle {
  font-size: 1.6875rem;
  line-height: 2.125rem;
  letter-spacing: 0.17px;
  font-family: "VisueltPro-Light";
}
.eventItemHover {
  border: solid 1px transparent;
}
.eventItem:hover {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.15);


}
.buttonLink:hover  {
  color: #0588da;


}

.newsSection{
  background: #f5f5f5

}
.eventItem:hover img {
  scale: 1.1;
}
.linkIcon{
  color: #0588da;
  transform: translateX(0px);
  display: inline-block;
  transition: transform ease 0.3s;
}
.swiperBtnLink .btnIcon,.optionButton .btnIcon {
  transform: translateX(0px);
  display: inline-block;
  transition: transform ease 0.3s;
  color: black;
  }

.swiperBtnLink:hover .btnIcon,.optionButton:hover .btnIcon ,.swiperBtnLink:hover .linkIcon {
  transform: translateX(10px);
  }


.eventItem {
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
  background: #ffffff;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
  }
.eventItem img {
  scale: 1;
  transition: scale 0.4s ease-in-out;
  
}
.cardDesctiption {
  font-family: "VisueltPro-Light";
  /* height: 178px; */
  font-size: 16px;
  line-height: 1.4375rem;
  letter-spacing: 0.25px;
}
.EventImage {
  width: 100%;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #271f67, #0588da);
  background-size: 100% auto;
  /* height: 250px; */
}
@media (max-width: 992px) {
  .carousel-container {
    padding-top: 0px;
  }
}
@media (max-width: 576px) {
  .swiper-button-next:after, .swiper-button-prev:after{
    font-size: 25px;
  }
  .swiper-button-prev, .swiper-rtl .swiper-button-next{
    left: 0px;
  }
  .swiper-button-next, .swiper-rtl .swiper-button-prev{
    right: 0px;
  }
  .EventImage {
    height: auto;
  }
  .carousel-container {
    padding-left: 0px;
  }
}

/* @media (min-width :200px) and (max-width:576px) {
    .carousel-container {
        padding-left: 3px;
    }
}
@media (min-width :577px) and (max-width:768px) {
    .carousel-container {
        padding-left: 10px;
    }
}
@media (min-width :768px) and (max-width:1200px) {
    .carousel-container {
        padding-left: 20px;
    }
} */
