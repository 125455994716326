@font-face {
  font-family: "VisueltPro-Regular";
  src: url(../fonts/VisueltPro-Regular.ttf);
}
@font-face {
  font-family: "VisueltPro-Light";
  src: url(../fonts/VisueltPro-Light.ttf);
}
@font-face {
  font-family: "VisueltPro-Bold";
  src: url(../fonts/VisueltPro-Bold.ttf);
}
.swiper {
  width: 100%;
}

.sliderItem {
  background-size: 100% auto;
  width: 100%;
  height: 100%;
}

.sliderImage .mobileHover{
  display: none;
  background-position: 50%;
  background-size: cover;
  height: 100%;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}



.swiper-pagination-bullet {
  width: 18px !important;
  height: 18px !important;
  background: rgb(166, 157, 157) !important;
  opacity: 1 !important;
}
/* @media (min-width: 768px) and (max-width: 991.98px){

  .sliderTitle {
      font-size: 2.8125rem;
      line-height: 2.9375rem !important;
  }
} */
@media (min-width: 576px) and (max-width: 767.98px) {
  .sliderTitle {
    font-size: 30px;
    line-height: 2.75rem !important;
    letter-spacing: 0.11px;
  }
}
@media (max-width: 575.98px) {
  .sliderTitle {
    font-size: 20px;
    line-height: 1.5rem !important;
    letter-spacing: 0.11px;
    font-family: "VisueltPro-Bold" !important;
  }
}
.swiper-pagination-bullet-active {
  background: #ffffff !important;
}
.sliderTitle {
  font-family: "VisueltPro-Light";
  font-weight: 400;
  line-height: 3.125rem;
  letter-spacing: 0.12px;
  /* font-size: 3rem; */
}
.swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  position: relative;
  transition: transform ease 0.3s;
}

.swiper-slide .title {
  font-size: 25px;
  line-height: 50px;
  font-weight: 700;
  font-family: "VisueltPro-Regular";
}

.swiper-slide .subtitle {
  font-size: 21px;
}

.swiper-slide .text {
  font-size: 14px;
  max-width: 400px;
  line-height: 1.3;
}

.buttonslider {
  /* background-color: #ffffff; */
  color: #fff !important;
  border: solid 1px #ffffff;
  /* color: #0588da !important; */
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
  font-family: "VisueltPro-Bold";
  padding: 5px 20px;
  display: inline-flex;
  border-radius: 50px;
  /* text-transform: capitalize; */
  justify-content:center;
  align-items: center;
}
.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
  display: none;
}
.onhoverslider {
  display: none;
  margin-top: 40%;
}
.imageslider {
  height: 80px;
}
.swiper-slide:hover .onhoverslider {
  display: block;
}
.mySwiper:hover .onhoverslider {
  display: block;
  margin-top: 10%;
}
.mySwipe {
  min-height: 70vh;
}
.swiper-slide-content2 {
  background-position: center;
  background-size: cover;
  width: 600px;
  /* height: 300px; */
  position: absolute;
  top: 22%;
  left: 10%;
  display: flex;
  flex-direction: column;
}
.imageslider {
  display: block;
}
.imageslider2 {
  display: none;
}
.sliderImage {
  height: 100%;
  /* background-image: none; */
  background-size: cover;
  background-position: center center;
}
.sliderImage2 {
  height: 80vh;
  width: 100%;
}
.mySwiper:hover .sliderImage img.sliderImgHover {
  opacity: 1;
}
.mySwiper:hover .sliderImage img {
  opacity: 0;
  /* display: none; */
}
.mySwiper:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.loaderSlider {
  width: 100vw;
  height: 70vh;
}
.mySwiper.notloaded {
  position: relative;
  width: 100%;
  min-height: 70vh;
}
.mySwiper:hover .imageslider {
  display: none;
}
.mySwiper:hover .imageslider2 {
  display: block;
}
.mySwiper .sliderImage img.sliderImgHover {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mySwiper .sliderImage img {
  transition: all 0.2s ease-out 0s;
  -webkit-transition: all 0.2s ease-out 0s;
}
@media (max-width: 992px) {
  .swiper-slide .title {
    font-size: 20px;
  }
  .swiper-slide:hover .onhoverslider {
    display: block;
    margin-top: 25%;
  }
  .buttonslider {
    font-size: 15px;
  }
}
@media (max-width: 768px) {
  .swiper-slide .title {
    font-size: 18px;
    line-height: 25px;
  }
  .swiper-slide:hover .onhoverslider {
    display: block;
    margin-top: 15%;
  }
  .buttonslider {
    font-size: 13px;
  }
  .sliderImage2 {
    height: 50vh;
    width: 100%;
  }
}
@media (min-width: 577px) {
  .sliderImage {
    background-image: none !important;
  }

}
@media (max-width: 576px) {
  .swiper-pagination-bullet {
    width: 10px !important;
    height: 10px !important;
    /* background: rgb(166, 157, 157) !important; */
    opacity: 1 !important;
  }
  .mySwiper .onhoverslider{
    display: block !important;
  }
  .onhoverslider{
    margin-top: 0% !important;
  }
  .swiper-slide .title {
    font-size: 14px;
    line-height: 20px;
  }
  .swiper-slide:hover .onhoverslider {
    display: block;
    margin-top: 5%;
  }
  .buttonslider {
    font-size: 10px;
    display: inline-block;
  }
  .swiper-slide-content2 {
    left: 5% !important;
    top: 20% !important;
    width: 250px;
    /* height: 300px; */
  }
  .sliderImage2 {
    height: 300px;
    width: 100%;
  }

  .sliderImage .mobileHover.active{
    display:block;
  }
  
  /* .swiper {
   height: 350px;
  
  } */
  .mySwiper .swiper-slide img {
    height: 100%;
    opacity: 0 !important;
    height: 300px;
  }

}
