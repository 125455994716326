.imageHeaderContainer{
  width: 100%;
  height: 450px;
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.imageContainer5 {
    width: 100%;
  
    background-size: 100% auto;
    background-repeat: no-repeat;
    height: 300px;
    /* background-image: url("../icons/productImage.png"); */
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0px;
  }
  
  @media (max-width:576px) {
    .imageContainer5 {
      flex-direction: column;
      background-image: none !important;
      padding: 0px !important;
      height: auto !important;
    }
    
  }