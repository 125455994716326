.imagecontainer3 {
  width: 100%;

  background-size: 100% auto;
  height: 550px;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  padding: 30px;
  background-position: center center;
}
.topRectangle {
  width: 130px;
  height: 10px;
  background-color: #0588da;
  margin-bottom: 5px;
}
.storyText {
  font-size: 18px;
  font-weight: 400;
  line-height: 31px;
}
.ourCultureContainer {
  background-size: auto 100%;
  background-repeat: no-repeat;
  height: 600px;
}
.ourCultureTitle {
  font-size: 40px;
  font-weight: 700;

  text-align: center;
}
.ourCultureDescription {
  font-size: 18px;
  line-height: 31px;
  font-weight: 400;
  text-align: center;
}
.containerBackground {
  width: 430px;
  height: 300px;
  position: absolute;
  right: 0px;
  margin-top: 400px;
  z-index: 1;
}
@media (max-width: 768px) {
  .ourCultureTitle {
    font-size: 25px;
    line-height: 30px;
  }
  .ourCultureDescription {
    font-size: 15px;
    line-height: 20px;
  }
  .containerBackground {
    display: none;
  }
}
@media (max-width: 576px) {
  .imagecontainer3 {
    flex-direction: column;
    background-image: none !important;
    padding: 0px;
    height: auto;
  }
  .storyText {
    font-size: 15px;
  }
  .ourCultureContainer {
    height: 450px;
  }
}
