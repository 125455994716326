.appContent{
  display: none;
}
#root.loaded .appContent{
  display: block;
}
#root.loaded .mainLoaded{
  display: none;
}
.defaultFont:not(i),.defaultFont *:not(i) {
  font-family: VisueltPro-Regular !important;
}
.news-events-title{
  min-height: 100px;
}
.news-events-description{
  min-height: 70px;
}
.news-title{
  min-height: 80px;
}
.news-description{
  min-height: 70px;
}

.mega-visibility{
  visibility: hidden;
}

.swiper-button-next, .swiper-button-prev{
  color: #271f67;
}

.swiper-button-next:after, .swiper-button-prev:after{
  color: #271f67;
  padding-left: 20px;
  padding-right: 20px;

}

@media screen and (max-width: 992px){
  .mega-box{
      max-height: initial ;
  }
  #showDrop:checked~.drop-menu, #showMega2:checked~.mega-box, #showMega3:checked~.mega-box, #showMega4:checked~.mega-box, #showMega:checked~.mega-box{
    max-height: initial !important;
  }
}

