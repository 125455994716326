@font-face {
  font-family: "VisueltPro-Light";
  src: url(../fonts/VisueltPro-Light.ttf);
}
.linkItems {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
  /* background-image: url("../icons/placeholder2.png"); */
  min-height: 300px;
}

.linkTitle {
  font-size: 40px;
  font-weight: 900;
  line-height: 50px;
  z-index: 2
}

.linkDescriptionContainer {
  /* border: solid 1px red; */
  width: 85%;
  /* height: 100px; */
  margin-top: -11px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 2;
}

.links-section {
  position: relative;
  padding-top: 40px;
  margin-bottom: -60px;
}

.links-section::before {
  background-image: url(../icons/Frame-2.png);
  content: '';
  position: absolute;
  width: 50%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: left top;
  top: 7%;
  left: 0;
  z-index: -1;
  background-size: contain;
}

.linkDescription {
  font-family: "VisueltPro-Light";
  /* height: 178px; */
  font-size: 16px;
  line-height: 1.4375rem;
  letter-spacing: 0.25px
}

.linksOneItem {
  position: relative;
}

.linksOneItem:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000;
  left: 0;
  top: 0;
  opacity: 0.3;
  z-index: 1;
}
.swiperBtnLink:hover .linkDescription{
  color: #0588da;
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .linkTitle {
    font-size: 30px;
  }

  .linkDescriptionContainer {
    margin-top: -15px;
    z-index: 2;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .linkTitle {
    font-size: 30px;
  }

  .linkDescriptionContainer {
    margin-top: -15px;
  }
}

@media (min-width: 301px) and (max-width: 576px) {
  .linkItems{
    background-size: auto 100%;
  }
  .linkTitle {
    font-size: 25px;
  }

  .linkDescriptionContainer {
    margin-top: -18px;
  }

  .linkDescription {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

@media (max-width: 300px) {
  .linkTitle {
    font-size: 20px;
  }

  .linkDescriptionContainer {
    margin-top: -18px;
  }

  .linkDescription {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }

  .linkItems {
    min-height: 200px;
  }
}

@media (max-width:768px) {
  .links-section::before {
 
    top: 3%;
    
  }
}