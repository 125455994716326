@font-face {
  font-family: "VisueltPro-Light";
  src: url(../fonts/VisueltPro-Light.ttf);
}
@font-face {
  font-family: "VisueltPro-Medium";
  src: url(../fonts/VisueltPro-Medium.ttf);
}
.parallax {
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(to bottom, #271f67, #0588da);
  min-height:600px;
}

.parallax h2 {
  font-size: 3rem;
  line-height: 4.125rem;
  letter-spacing: 0.12px;
  font-family: "VisueltPro-Light";
  font-weight: 400;
  font-family: "VisueltPro-Light";
  color: #ffffff;
}

.our-story-section {
  position: relative;
}

.our-story-section::before {
  background-image: url(../icons/Frame-1.png);
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: right bottom;
  bottom: -40%;
  right: 0;
  z-index: -1;
  background-size: contain;
}

.parallax .ourStoryDesc {
  max-width: 600px;
  background-color: #ffffff;
  font-family: "VisueltPro-Light";
  font-size: 16px;
  line-height: 1.4375rem;
  letter-spacing: .25px;
  /* font-style: normal;
  font-weight: 400;
  line-height: 30px; */
  /* or 225% */

  color: #505050;
}

.meetTheTeam {
  cursor: pointer;
  display: inline-block;
  width: auto !important;
  font-family: "VisueltPro-Medium";
  font-size: 1rem;
  line-height: 1.125rem;
  letter-spacing: 2.1px;
  text-transform: uppercase;
  margin-top: 70px;
  color: #505050;
}
.meetTheTeam:hover {
  color: #0588da;
}
.meetTheTeam .btn-arrow {
  transform: translateX(0);
  transition: transform ease 0.3s;
}

.meetTheTeam:hover .btn-arrow {
  transform: translateX(15px);
}

@media (max-width: 576px) {
  .ourStoryDesc {
    max-width: 600px;
    background-color: #ffffff;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    /* or 225% */

    color: #505050;
  }

  .parallax {
    margin-top: 20px;
    height: 300px;
  }

  .parallax p {
    line-height: 30px;
    font-size: 15px;
  }

  .meetTheTeam {
    margin-top: 25px;
  }
  .our-story-section::before {
    content: none;
    position: absolute;
  }
}
@media (max-width: 768px) {
  .our-story-section::before {
    bottom: -25%;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .parallax h2 {
    font-size: 2.8125rem;
    line-height: 3.9375rem;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .parallax h2 {
    font-size: 2.625rem;
    line-height: 3.75rem;
    letter-spacing: 0.11px;
  }
}
@media (max-width: 576px) {
  .parallax h2 {
    font-size: 2.375rem;
    line-height: 3.5rem;
    letter-spacing: 0.11px;
  }
}

@media (max-width: 300px) {
  .parallax h2 {
    font-size: 2.375rem;
    line-height: 3.5rem;
    letter-spacing: 0.11px;
  }
}

@media (max-width: 768px) {
  .storyImage {
    height: 300px;
    background-size: cover;
  }
}
