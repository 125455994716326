
.background-blue {
  background-color: #280181;
}
.background-lightblue {
  background-color: #0588da;
}
.background-purple {
  background-color: #5e2b7b;
}
.background-lightgrey {
  background-color: #FAFAFA;
}
.background-lightergrey {
  background-color: #f2f2f2;
}

.background-whitegrey {
  background-color: #e5e5e5;
}
.background-white {
  background-color: #ffffff;
}
.background-darkgrey {
  background-color: #727678;
}
.background-grey {
  background-color: #505050;
}
.background-lightwhite {
  background-color: #f9f9f9;
}
.background-semigrey {
  background-color: #D9D9D9;
}

.color-blue {
  color: #280181;
}
.color-darkblue {
  color: #271f67 !important;
}
.color-lightblue {
  color: #0588da !important;
}
.color-facebook {
  color: #1877f2 !important;
}
.color-instagram {
  color: #E1306C !important;
}
.color-youtube {
  color: #E62117 !important;
}
.color-linkedin {
  color: #0077B5 !important;
}
.color-purple {
  color: #5e2b7b;
}
.color-lightgrey {
  color: #fafafa;
}
.color-lightergrey {
  color: #f2f2f2;
}

.color-whitegrey {
  color: #e5e5e5;
}
.color-white {
  color: #ffffff;
}
.color-darkgrey {
  color: #727678 !important;
}
.color-grey {
  color: #505050;
}
.color-lightwhite {
  color: #f9f9f9;
}
.color-black{
    color: black !important;
}
.color-red {
  color: red;

}
.color-green {
  color: green;
  
}
