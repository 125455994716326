.imageContainer6 {
  width: 100%;

  background-size: 100% auto;
  background-repeat: no-repeat;
  height: 550px;
  /* background-image: url("../icons/productImage.png"); */
  display: flex;
  align-items: center;
  padding: 30px;
  background-position: center center;
}
.phoneImage{
  display: none;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 300px;
}
.phoneImage2{
  display: none;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 300px;
}
.impactNumber {
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  text-align: center;
  border-bottom: solid 3px #0588da;
}
.impactDescription {
  line-height: 31px;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}
.blueContainer {
  width: 220px;
  height: 200px;
  margin-left: -150px;
  z-index: 0;
}
.loadMoreButton{
  line-height: 31px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}
@media (max-width: 768px) {
  .blueContainer {
    display: none;
  }
}
@media (max-width: 576px) {
  .imageContainer6 {
    flex-direction: column;
    background-image:none !important; 
    padding: 0px;
    height: auto !important;
  }
.phoneImage{
    display: flex;
  }
  .phoneImage2 {
    display: block;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 300px;
}
}
