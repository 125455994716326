.pageHeader {
  /* height: 650px; */
  width: 100%;
}
.imageContainer2 {
  width: 100%;

  background-size: 100% auto;
  height: 100%;
  /* background-image: url("../icons/productImage.png"); */
  display: flex;
  align-items: flex-end;
  /* justify-content: center; */
  padding: 30px;
  background-repeat: no-repeat;
  background-position: center center;
}

.hovereffect {
  /* width: 300px; */
  /* height: 200px; */
  transition: 0.5s;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.marketItem{
  overflow: hidden;
}
.pageheadermargin {
  margin-top: 10%;
}
.hovereffect {
  --bs-border-color-translucent: rgba(0, 0, 0, 0);
}

.imagecard {
  --bs-border-color-translucent: rgba(0, 0, 0, 0);
}

.card-body {
  --bs-card-spacer-y: 0rem;
  --bs-card-spacer-x: 0rem;
  width: 100%;
}

.imagecard {
  position: relative;
  transition:scale 0.4s ease-in-out;
  background-image: linear-gradient(to bottom, #271f67, #0588da);
  background-size: 100% auto;
  /* width: 250px; */
  /* height: 300px; */
  z-index: 1;
  opacity:1;
}
.marketSwiper .swiper-button-next {
  /* display: none; */
}

.cardDesctiption i{
  display:inline-block;
  transform: translateX(0px);
  transition:  transform ease 1s;
  color:#0588da
}

.hovereffect:hover i{
  transform: translateX(10px);
}

.marketSwiper .swiper-button-prev {
  /* display: none; */
}
.marketSwiper .swiper-slide-active{
  background-color: white;
}
.marketSwiper, .newsSwiper{
  padding-left: 50px;
  padding-right: 50px;
}

.swiperBtnLink, .swiperBtnLink .linkDescription{
  text-transform: uppercase;
  font-family: "VisueltPro-Medium";
  font-size: 1rem;
  line-height: 1.125rem;
  letter-spacing: 2.1px;
}
.insideDescription {
  padding: 20px;
  /* padding-bottom: 50px; */
}
.descriptionhover {
  position: absolute;
  bottom: 0;
  pointer-events: none;
  transition:height 0.4s ease-in-out;
  
  height: 0px;
  z-index: 2;
  background-color: white;
  padding: 5px;
  width: 93%;
  overflow: hidden;
}

.hovereffect:hover .descriptionhover {
  height: 150px;
}
.hovereffect:hover .imagecard {
  scale: 1.1;
  opacity:0.8;
}

.descriptionContainer {
  /* height: 200px; */
  max-width: 600px;
  margin-top: 20%;
  position: relative;
  background-color: #f9f9f9;
  /* margin-bottom: -10%; */
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
}
.descriptionContainer5 {
  /* height: 200px; */
  max-width: 600px;
  /* width: 100px; */
  /* margin-top: 20%; */
  position: relative;
  background-color: #f9f9f9;
  /* margin-bottom: -10%; */
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
}
.firstTitle {
  font-size: 20px;
  font-weight: 900;
  line-height: 32px;
}
.SecondTitle {
  font-size: 35px;
  line-height: 50px;
  font-weight: 700;
}
.Description {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}
.EachItem {
  background-size: 70% auto;
  background-repeat: no-repeat;
  min-height: 600px;
  
}
.tobBorder {
  border: solid 5px #0588da;
  width: 300px;
  position: absolute;
  margin-top: -5px;
}
.eachRules {
  border-left: solid 5px #0588da;
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
}

@media (max-width: 768px) {
  .EachItem {
    background-size: 100% 100% !important;
  }
  .marketSwiper{
    padding-left: 0px;
  }
}
@media (max-width: 576px) {

  

  .marketSwiper, .newsSwiper{
    padding-left: 0px;
  }
  
  .pageheadermargin {
    margin-top: 0px !important;
  }
  .descriptionContainer {
    margin-top: 0px !important;
  }
  .pageHeader {
    height: auto !important;
  }
  .imageContainer2 {
    flex-direction: column;
    background-image: none !important;
    padding: 0px;
    height: auto !important;
  }
  .EachItem {
    background-image: none !important;
    /* background-size: 70% 500px; */
    /* background-repeat: no-repeat; */
    height: auto !important;
    min-height: auto !important;
    /* border: solid 1px black; */
  }
  .firstTitle {
    font-size: 16px;
  }
  .SecondTitle {
    font-size: 25px;
  }
  .Description {
    font-size: 14px;
  }
  .tobBorder {
    width: 200px;
  }

  .hovereffect.active .descriptionhover {
    height: 150px;
  }
  .hovereffect.active .imagecard {
    scale: 1.1;
    opacity:0.8;
  }

  .hovereffect.active i{
    transform: translateX(10px);
  }
 
}
@media (max-width: 300px) {
  .EachItem {
    /* background-image: url("../icons/productImage.png"); */
    /* background-size: 100% 300px; */
    /* background-repeat: no-repeat; */
    height: 300px;
  }
  .eachRules {
    border-left: solid 5px #38c1ef;
    font-size: 8px;
    font-weight: 400;
    line-height: 14px;
  }
  .tobBorder {
    width: 150px;
  }
  .firstTitle {
    font-size: 12px;
    line-height: 18px;
  }
  .SecondTitle {
    font-size: 18px;
    line-height: 25px;
  }
  .Description {
    font-size: 10px;
    line-height: 12px;
  }
  .pageHeader {
    height: 350px;
  }
  .imageContainer {
    height: 300px;
  }
}
