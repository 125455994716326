.imageContainer4 {
  width: 100%;

  background-size: 100% auto ;
  height: 550px ;
  background-repeat: no-repeat;
  /* background-image: url("../icons/productImage.png"); */
  display: flex;
  align-items: center;
  padding: 30px;
  background-position: center center;
}
.itemTitle{
  font-size: 20px;
  line-height: 40px;
  font-weight: 500;
}
.itemDescription{
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}
.itemButton{
  font-size: 16px;
  font-weight:400 ;
  line-height: 32px;
}
.linkIcon{
  color: #0588da;
  transform: translateX(0px);
  display: inline-block;
  transition: transform ease 0.3s;
}
.newsButton .linkIcon {
  transform: translateX(0px);
  display: inline-block;
  transition: transform ease 0.3s;
  }

.newsButton:hover .linkIcon {
  transform: translateX(10px);
  }
@media (max-width:576px) {
  .imageContainer4 {
    flex-direction: column;
    background-image: none !important;
    padding: 0px;
    height: auto !important;
  }
  .itemTitle{
  font-size: 20px;
  line-height: 35px;
  font-weight: 500;
}
.itemDescription{
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
}
.itemButton{
  font-size: 17px;
  font-weight:400 ;
  line-height: 20px;
}
}
