* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat-Regular";
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url(../fonts/Montserrat-Medium.ttf);
  /* font-weight: 400; */
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url(../fonts/Montserrat-Regular.ttf);
  /* font-weight: 400; */
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url(../fonts/Montserrat-Bold.ttf);
  /* font-weight: 400; */
}
@font-face {
  font-family: "VisueltPro-Light";
  src: url(../fonts/VisueltPro-Light.ttf);
}
@font-face {
  font-family: "VisueltPro-Regular";
  src: url(../fonts/VisueltPro-Regular.ttf);
}
@font-face {
  font-family: "VisueltPro-Medium";
  src: url(../fonts/VisueltPro-Medium.ttf);
}
@font-face {
  font-family: "VisueltPro-Bold";
  src: url(../fonts/VisueltPro-Bold.ttf);
}
nav {
  position: unset;
  z-index: 99;
  width: 100%;
}

nav .wrapper {
  position: relative;
  padding: 0px 30px;
  height: 70px;

  margin: auto;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}

.wrapper .logo a {
  color: #f2f2f2;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}

.wrapper .logo {
  width: 30%;
  cursor: pointer;
  line-height: 70px;
  /* text-align: center; */
}

.wrapper .logo img {
  width: 70%;
  /* text-align: center; */
}

.wrapper .nav-links {
  display: inline-flex;
  /* background-color: red; */
  z-index: 2;
}

.nav-links li {
  list-style: none;
  color: #0588da;
}
.eachRow {
  border-left: solid 1px black;
  width: 1px;
  text-align: left;
}
.eachRow:first-child {
  border-left: 0px;
}
.nav-links li .navbarlinks {
  color: #737678;
  text-decoration: none;
  line-height: 70px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  letter-spacing: 2px;
  /* padding: 9px 5vw; */
  border-radius: 5px;
  /* transition: all 0.3s ease; */
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #0588da;
}

.navbarlinks2 {
  position: relative;
  display: inline-block;
  font-family: "VisueltPro-Regular";
}

/* .hover-underline-animation::after {
  content: "";
  position: absolute;
  width: 50%;
  transform: scaleX(0);
  height: 2.5px;
  bottom: 20px;
  left: 25%;
  background-color: #0588da;
  transform-origin: bottom center;
  transition: transform 0.25s ease-out;
} */
.navbarlinks3:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 10px;
  left: 0;
  background-color: #0588da;
  transform-origin: bottom center;
  transition: transform 0.25s ease-out;
}

.navbarlink:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 20px;
  left: 0;
  background-color: #0588da;
  transform-origin: bottom center;
  transition: transform 0.25s ease-out;
}

li:hover > .hover-underline-animation .navbarlink::after,
.navbarlinks2:hover::after,
.navbarlinks3:hover::after,
.navbarlink:hover::after,
li.active > .hover-underline-animation .navbarlink::after,
.navbarlinks2.active::after,
.navbarlinks3.active::after,
.navbarlink.active::after {
  transform: scaleX(1);
  transform-origin: bottom center;
}

.navbarlink {
  display: inline-block;
  position: relative;
  font-family: "VisueltPro-Regular";
}
.navbarlinks3 {
  display: inline-block;
  position: relative;
  font-family: "VisueltPro-Light";
}
.nav-links li .navbarlinks2 {
  color: black;
  text-decoration: none;
  font-size: 1rem;
  letter-spacing: 1.5px;
  font-family: "VisueltPro-Medium";
  line-height: 50px;
  /* font-weight: 400; */
  /* cursor: pointer; */
  /* padding: 9px 5vw; */
  border-radius: 5px;
  /* transition: all 0.3s ease; */
}
.nav-links li .navbarlinks3 {
  color: #737678;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 40px;
  /* line-height: 10px !important; */
  /* font-weight: 400; */
  cursor: pointer;
  /* padding: 9px 5vw; */
  border-radius: 5px;
  /* transition: all 0.3s ease; */
}

.nav-links .mobile-item {
  display: none;
}

.nav-links .drop-menu {
  position: absolute;
  background: #242526;
  width: 180px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box {
  /* transition: all 0.3s ease; */
  top: 65px;
  opacity: 1;
  visibility: visible;
}

.drop-menu li a {
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
  border-radius: 0px;
}

.mega-box {
  position: absolute;
  left: 0;
  width: 100%;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  background-color: #fafafa;
}

.background-lightblueLine {
  width: 45px;
  height: auto;
  margin-top: -20px;
  margin-right: -20px;
  margin-left: -20px;
  margin-bottom: -20px;
}

.mega-box .content {
  /* padding: 25px 200px; */
  display: flex;
  width: 100%;
  /* box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); */
}

.content .row header {
  color: black;
  font-size: 20px;
  font-weight: 500;
}

.content .mega-links {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  /* border-right: 1px solid black; */
}

.mega-links li {
  padding: 0 20px;
  /* border-left: 2px solid #737678; */
}

/* .mega-links li a {
  padding: 0 20px;
  color: #d9d9d9;
  font-size: 17px;
} */
.mega-links li a:hover {
  color: #f2f2f2;
}

.wrapper .btn {
  color: #505050;
  font-size: 20px;
  cursor: pointer;
  display: none;
}

.wrapper .btn.close-btn {
  position: absolute;
  right: 30px;
  top: 10px;
}

.liItems {
  width: 21%;
  text-align: center;
  display: inline-block;
}

.liItems .desktop-item {
  position: relative;
  width: 100%;
}

.liItems .desktop-item::before {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #0588da;
  display: inline-block;
  position: absolute;
  left: 0px;
  top: 45%;
  content: "";
}
.liItems .desktop-item.lastOne::after {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #0588da;
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 45%;
  content: "";
}
.liItems .desktop-item.lastOne::before {
  width: 0px;
  height: 0px;
  border-radius: 50%;
  background-color: #0588da;
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 45%;
  content: "";
}
.liItems.contactLink .desktop-item::after {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #0588da;
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 45%;
  content: "";
}

/* .liItems::before{
  width: 5px;
  height: 5px;
  border-radius: '50%';
  background-color: #0588da;
  display: inline-block;
  position: absolute;
  right: 0px;
  bottom: 0px;
  content: '';
} */
.searchImage {
  width: 20px;
  text-align: right;
  justify-content: end;
  cursor: pointer;
  line-height: 70px;
}
.dialogModal {
  position: absolute !important;
  top: 45%;
  width: 100% !important;
  max-width: 100% !important;
  display: flex;
  justify-content: center;
}
.searchInput {
  width: 100%;
  height: 50px;
  display: block;
  border-radius: 5px;
  background-color: transparent;
  border: solid 0px;
  color: white;
  outline: none !important;
}
.mobile-item {
  position: relative;
}
/* .searchInput:focus-visible{
  outline: none;
} */
.mobile-item:after {
  content: "\f078";
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20%;
  top: -2px;
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  font-weight: var(--fa-style, 900);
}

.liItems input:checked + .mobile-item:after {
  content: "\f077";
}
@media (min-width: 850px) and (max-width: 1199.98px) {
  .nav-links li .navbarlinks {
    font-size: 0.75rem;
    letter-spacing: 1.75px;
  }
}
@media screen and (max-width: 992px) {
  .liItems.contactLink .desktop-item::after {
    display: none;
  }
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  .liItems.contactLink{
    height: 50px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
  }
  .liItems.contactLink .desktop-item::before,
  .liItems.aboutLink .desktop-item::before {
    display: none;
  }

  .desktop-item {
    text-align: start;
  }

  .wrapper .logo {
    width: 70%;
    /* text-align: center; */
  }

  .wrapper .btn {
    display: block;
  }

  .wrapper .nav-links {
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 350px;
    top: 0;
    left: -100%;
    background: #242526;
    display: block;
    padding: 50px 0px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
    transition: all 0.3s ease;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #242526;
  }

  ::-webkit-scrollbar-thumb {
    background: #3a3b3c;
  }

  #menu-btn:checked ~ .nav-links {
    left: 0%;
  }

  #menu-btn:checked ~ .btn.menu-btn {
    display: none;
  }

  #close-btn:checked ~ .btn.menu-btn {
    display: block;
  }

  .nav-links li {
    /* margin: 15px 10px; */
    list-style: none;
    width: 100%;
  }

  .nav-links li a {
    padding: 0 20px;
    display: block;
    font-size: 20px;
  }

  .navbarlink:after,
  .navbarlinks2:after {
    display: none;
  }

  .navbarlink {
    display: block;
    color: #f2f2f2;
  }
  .navbarlinks:hover {
    background: #3a3b3c;
  }

  .nav-links .drop-menu {
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    /* transition: all 0.3s ease; */
  }

  #showDrop:checked ~ .drop-menu,
  #showMega:checked ~ .mega-box {
    max-height: 80vh;
  }

  #showMega2:checked ~ .mega-box {
    max-height: 80vh;
  }
  #showMega3:checked ~ .mega-box {
    max-height: 80vh;
  }
  #showMega4:checked ~ .mega-box {
    max-height: 80vh;
  }

  .nav-links .liItems:not(.contactLink, .aboutLink) .desktop-item {
    display: none;
  }

  .nav-links .mobile-item {
    display: block;
    color: #f2f2f2;
    font-size: 1rem;
    letter-spacing: 2px;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
    border-radius: 5px;
    /* transition: all 0.3s ease; */
    text-align: start;
    font-family: "VisueltPro-Regular";
  }

  .searchImage {
    text-align: start;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0px 20px;
    /* margin-top: 20px; */
  }
  .searchImage > img {
    height: 25px;
  }

  .nav-links .mobile-item:hover {
    background: #3a3b3c;
  }

  .drop-menu li {
    margin: 0;
  }

  .drop-menu li a {
    border-radius: 5px;
    font-size: 18px;
  }

  .mega-box {
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    transition: max-height 0.4s ease-in-out;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
  }

  .mega-box .content {
    box-shadow: none;
    flex-direction: column;
    padding: 20px 20px 0 20px;
  }

  .mega-box .content .row {
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
  }

  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2) {
    border-top: 0px;
  }

  .content .row .mega-links {
    border-left: 0px;
    padding-left: 15px;
  }

  .row .mega-links li {
    margin: 0;
  }

  .content .row header {
    font-size: 19px;
  }
}

nav input {
  display: none;
}

.body-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}

.body-text div {
  font-size: 45px;
  font-weight: 600;
}

.navbar-image img {
  /* width: 481px; */
  /* height: 211px; */
}

.mega-links {
  padding: 0px;
}

.mega-links li {
  list-style: none;
}

/* .mega-links li a {
  padding-left: 50px;
  padding-right: 50px;
} */
.navbar-phone {
  padding-left: 4%;
  padding-right: 1%;
}

.modal-content {
  border: 0px !important;
  background-color: transparent !important;
  width: 80% !important;
}
.body-modal {
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: row;
  border-bottom: solid 1px #0588da;
}
.formInput {
  width: 100%;
}
/* .searchInput:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
} */
.searchInput::placeholder {
  color: white;
}

.searchButton {
  font-size: 13px;
  background-color: transparent !important;
  color: white !important;
}
.closeButton {
  background-color: #505050 !important;
  color: white !important;
}
@media (min-width:2000px) {
  .wrapper .logo{
    width: 12%;
  }
}
@media (max-width: 992px) {
  .eachRow {
    border-left: solid 0px black;
    padding-left: 30px !important;
  }
  .nav-links li .navbarlinks2 {
    color: white;
  }
  .navLinkContainer {
    align-items: flex-end;
  }

  .navbar-phone {
    display: none;
  }

  .mega-box .content {
    align-items: flex-end;
  }

  .nav-flex {
    flex-direction: column;
  }

  .content .mega-links {
    border-right: none;
  }

  .mega-links li {
    padding: 0 0px;
    border-left: 0px solid black;
  }

  .mega-box {
    background-color: #242526;
    transition: max-height 0.4s ease-in-out;
  }
}

@media (max-width: 576px) {
  #exampleModal {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .modal-content {
    width: 100% !important;
}
  .eachRow {
    border-left: solid 0px black;
    padding-left: 30px !important;
  }
  .searchInput {
    font-size: 10px;
  }
  nav .wrapper {
    padding: 0px 15px;
  }

  .wrapper .logo {
    width: 200px;
  }

  .wrapper .logo > img {
    width: 200px;
  }
}

@media (max-width: 300px) {
  .wrapper .logo {
    width: 200px;
  }

  .wrapper .logo > img {
    width: 200px;
  }
}
