@font-face {
    font-family: 'Montserrat-Regular';
    src: url(../fonts/Montserrat-Regular.ttf);
}
@font-face {
    font-family: 'VisueltPro-Regular';
    src: url(../fonts/VisueltPro-Regular.ttf);
}
@font-face {
    font-family: 'VisueltPro-Medium';
    src: url(../fonts/VisueltPro-Medium.ttf);
}
@font-face {
    font-family: 'VisueltPro-Light';
    src: url(../fonts/VisueltPro-Light.ttf);
}
@font-face {
    font-family: 'VisueltPro-Bold';
    src: url(../fonts/VisueltPro-Bold.ttf);
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: url(../fonts/Montserrat-Medium.ttf);
}
@font-face {
    font-family: "Montserrat-Regular2";
    src: url(../fonts/Montserrat-Regular.ttf);
    font-weight: 500;
  }
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SignWriting&display=swap');

.websiteInfo{
    font-family: 'VisueltPro-Regular';
    color: black !important;
    text-decoration: none;
}
@media screen {
    
}
.footerDiv{
    width: 100%;
}
@media (min-width:1200px) {
    .footerDiv{
        width: 33%;
    } 
}
.SignWriting{
    font-family: 'Noto Sans SignWriting', sans-serif;
}

.FooterContainer{
    /* height: 450px; */
    width: 100%;
    position:relative;
    margin-top:100px;
}
.facebook,.instagram,.youtube,.linkedin{
    cursor: pointer;
    color: #271f67 !important;
}
.facebook :hover{
    color: #1877f2 !important;

}
.instagram :hover{
    color: #E1306C !important;

}
.youtube :hover{
    color: #E62117 !important;

}
.linkedin :hover{
    color: #0077B5 !important;

}
.FooterContainer:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100px;
    top: -100px;
    left: 0;
    z-index: -1;
    background-image: linear-gradient(to right, #271f67, #0588da);
    }
.mainContainer{
    width: 100%;
    /* height: 380px; */
    margin: auto;
    padding: 25px;
    padding-top: 40px;
    padding-bottom: 40px;

}
.websiteInfo >p{
    margin-bottom: 0px;
    font-family: 'VisueltPro-Regular';
}
.syncButton{
    text-decoration: none;
    color: white !important;
}
.copyRight{
    width: 100%;
    padding: 20px;
    /* height: 70px; */
}
.copyRightText{
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
}
.footerHeaderTitle{
    font-size: 16px;
    text-decoration: none;
    line-height: 23px;
    /* font-weight: 700; */
    font-family: 'VisueltPro-Regular';
}

.subFooterTitle{
    font-size: 16px;
    text-decoration: none;
    line-height: 30px;
    /* font-weight: 700; */
    font-family: 'VisueltPro-Bold';
}
.footerSubTitle.SubSubTitle{
    font-size: 12px !important;
}
.footerSubTitle{
    font-size: 14px;
    line-height: 30px;
    font-weight: 500;
    color: #727678;
    font-family: 'VisueltPro-Light';
}
.footerSubTitle:hover{
    cursor:grab;
    color: #0588da;
}
.footerLogo{
    /* width: 240px; */
    /* height: 35px; */
    display: flex;
}
.Logo{
    width: 100%;
    height: auto;
}

@media (max-width: 576px) {
    .itemContainer{
        text-align: center;
    }
}