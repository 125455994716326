@font-face {
  font-family: "Montserrat-Regular";
  src: url(../fonts/Montserrat-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url(../fonts/Montserrat-Regular.ttf);
}


@font-face {
  font-family: "Montserrat-Bold";
  src: url(../fonts/Montserrat-Bold.ttf);
}
@font-face {
  font-family: 'VisueltPro-Regular';
  src: url(../fonts/VisueltPro-Regular.ttf);
}
@font-face {
  font-family: 'VisueltPro-Medium';
  src: url(../fonts/VisueltPro-Medium.ttf);
}
@font-face {
  font-family: 'VisueltPro-Light';
  src: url(../fonts/VisueltPro-Light.ttf);
}
@font-face {
  font-family: 'VisueltPro-Bold';
  src: url(../fonts/VisueltPro-Bold.ttf);
}
.overflowBody{
  overflow: visible !important;
}

.defaultFont {
  font-family: "VisueltPro-Regular";
}

.defaultFontBold {
  font-family: "VisueltPro-Bold";
}

.title {
  /* float: left;

  font-size: 32px;
  line-height: 64px;
  letter-spacing: 10px;
  display: block;
  text-align: start;
  font-weight: 900;
  font-family: "VisueltPro-Bold";
  padding-top: 4px; */
  font-size: 3rem;
  line-height: 4.125rem;
  letter-spacing: .12px;
  font-family: "VisueltPro-Light";
  font-weight: 400;
}

.SameTitle {
  font-size: 35px;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
  border-left: solid 10px #0588da;
  font-family: 'VisueltPro-Bold';
  display: inline-block;
}
.NoResult{
  height: 500px;
  font-family: 'VisueltPro-Regular';
  font-size: 30px;
  width: 100%;
}

.spinner-container {
  width: 100%;
  height: 100vh;
}

.impact-spinner-container {
  width: 100%;
  height: 100%;
}

.leaderTitle {
  font-size: 25px;
  line-height: 25px;
  font-weight: 900;
  font-family: "VisueltPro-Regular";
}

.leaderDescription {
  font-size: 20px;
  line-height: 15px;
  font-family: "VisueltPro-Regular";
}

a {
  color: transparent !important;
}
.imagecard .marketImage{
  opacity: 1 !important;
}
.EventImage .eventImage{
  opacity: 1 !important;

}
.imapctImage .secondImage {
  opacity: 1 !important;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #0588da;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.navmargin {
  margin-top: 70px;
}
.reveal{
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: all 1s ease;
  overflow-y: hidden;
}
.reveal.active{
  transform: translateY(0px);
  opacity: 1;
}
@media (min-width: 768px) and (max-width: 991.98px){

  .title {
      font-size: 2.8125rem;
      line-height: 3.9375rem;
  }
}
@media (min-width: 576px) and (max-width: 767.98px){

  .title {
      font-size: 2.625rem;
      line-height: 3.75rem;
      letter-spacing: .11px;
  }
}
@media (max-width: 576px) {
  .SameTitle {
    font-size: 20px;
    line-height: 30px;
  }

  .title {
    font-size: 2.375rem;
    line-height: 3.5rem;
    letter-spacing: .11px;
  }
}

@media (max-width: 300px) {
  .SameTitle {
    font-size: 15px;
    line-height: 30px;
  }

  .title {
    font-size: 2.375rem;
    line-height: 3.5rem;
    letter-spacing: .11px;
  }
}