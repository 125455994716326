@font-face {
  font-family: "VisueltPro-Light";
  src: url(../fonts/VisueltPro-Light.ttf);
}
.swiper {
  width: 100%;
}

.swiper-slide-content {
  background-position: center;
  background-size: cover;
  width: 300px;
  /* height: 300px; */
  position: absolute;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.swiper-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(
    to right,
    rgb(0 0 0 / 0%),
    rgba(0, 0, 0, 0)
  );
}
.impactSwiper{
  user-select: none;
  padding-top:9%;
}
.impactSwiper .swiper-button-next:after,
.impactSwiper .swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 50px;
  background: grey;
  width: 50px;
  height: 50px;
  font-size: 30px;
  text-align: center;
  color: #ffffff;
}

.impactSwiper .swiper-button-next:hover:after,
.impactSwiper .swiper-button-prev:hover:after {
  background: #0588da;
}

.generalTitle {
  position: relative;
}

.generalTitle::after {
  content: "";
  position: absolute;
  width: 110px;
  height: 5px;
  background-image: linear-gradient(to right, #271f67, #0588da);
  bottom: 15px;
}

.swiper-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(
    to right,
    rgb(0 0 0 / 0%),
    rgba(0, 0, 0, 0)
  );
}

.impactSwiper .swiper-button-next {
  top: 60%;
  right: 30%;
  width: 75px;
  display: flex;
  justify-content: center;
  height: 200px;
}

.impactSwiper .swiper-button-prev {
  top: 60%;
  left: 30%;
  width: 75px;
  display: flex;
  justify-content: center;
  height: 200px;
}

.impactTitle {
  font-size: 1.6875rem;
  line-height: 2.125rem;
  letter-spacing: 0.17px;
  font-family: "VisueltPro-Light";
  min-height: 60px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.impactdescription {
  display: none;
}

.impactTitle {
  display: none;
}

.swiper-slide-active {
  background-color: #f5f5f5;
}

.swiper-slide-active .impactTitle {
  display: flex;
}

.swiper-slide-active .impactdescription {
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  max-height: 180px;
  height: 180px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 8;
  
}

/* .imapctImage{
  height: 300px;
} */
.impactdescription {
  font-family: "VisueltPro-Light";
  /* height: 180px; */
  font-size: 16px;
  line-height: 1.4375rem;
  letter-spacing: 0.25px;
  /* text-overflow:ellipsis;
  overflow: hidden;  */
  /* font-weight: 400;
  line-height: 25px;
 
  overflow: hidden; */
}

.impactSwiper .swiper-slide-prev {
  transform: perspective(450px) rotateY(10deg) translate3d(0, 0, 45px);
}

.impactSwiper .swiper-slide-next {
  transform: perspective(450px) rotateY(-10deg) translate3d(0, 0, 45px);
}

.impact-container {
  background-image: url(../icons/circle-1.png);
  background-size: 25% auto;
  background-position: 0% 300px;
  background-repeat: no-repeat;


}

.impactSwiper .right1 {
  transform: perspective(1000px) rotateY(-20deg);
}

.impactSwiper .right2 {
  transform: perspective(600px) rotateY(-20deg) translate3d(80px, 0, 150px);
}

.impactSwiper .right3 {
  transform: perspective(700px) rotateY(-30deg) translate3d(290px, 0, 265px);
}

.impactSwiper .left1 {
  transform: perspective(1000px) rotateY(20deg);
}

.impactSwiper .left2 {
  transform: perspective(600px) rotateY(20deg) translate3d(-80px, 0, 150px);
}

.impactSwiper .left3 {
  transform: perspective(700px) rotateY(30deg) translate3d(-290px, 0, 265px);
}

@media (max-width: 992px) {
  .impactSwiper .swiper-button-next {
    top: 75%;
    right: 20%;
  }

  .impactSwiper .swiper-button-prev {
    top: 75%;
    left: 20%;
  }

  .impactTitle {
    font-size: 1.5625rem;
    line-height: 1.6875rem;
  }

  /* .impactdescription {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    height: 178px;
    overflow: hidden;
  } */
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .impactTitle {
    font-size: 1.5625rem;
    line-height: 2rem;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .impactTitle {
    font-size: 1.5625rem;
    line-height: 1.875rem;
  }
}
@media (max-width: 576px) {

  .swiper-slide{
    transform:none !important;
  }

  .impactTitle {
    font-size: 1.5625rem;
    line-height: 1.75rem;
  }
  .impactSwiper .swiper-button-next {
    top: 30%;
    right: -2%;
  }

  .impactSwiper .swiper-button-prev {
    top: 30%;
    left: -1%;
  }
  .impact-container {

    opacity: 1 !important;
    transform:translateY(0px) !important;
  
  }
  /* .imapctImage{
    height: 183px;
  } */
}
