.imageContainer {
  justify-content: start !important;
  padding: 30px !important;
  height: 400px !important;
  display: flex;
  align-items: flex-end !important;
  background-size: 100% auto !important;
  background-repeat: no-repeat;
  background-position: center center;
}
.HeaderDescription {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  overflow:hidden;
  /* text-align: center; */
}
.projectItem {
  /* background-image: url("../icons/productImage.png"); */
  background-size: 70% 600px;
  background-repeat: no-repeat;
  height: 600px;
  background-position: right;
}
.approachTitle {
  font-size: 50px;
  font-weight: 400;
  line-height: 64px;
}
.approachDescription {
  line-height: 31px;
  font-size: 24px;
  font-weight: 400;
}
.backgroundContainer{
  width: 300px;
  height: 200px;
  z-index: 1;
  margin-bottom: 287px;
  margin-left: -250px;
}
.EachItem2{
  background-size: 70% 500px;
  background-repeat: no-repeat;
  height: 600px;
}
@media (max-width: 768px) {
  .projectItem {
    background-size: 100% 100%;
    height: auto !important;
    background-image: none !important;
    display: flex;
    flex-direction: column;
  }

  .backgroundContainer{
    display: none;
  }
}
@media (max-width: 576px) {
  .imageContainer {
    flex-direction: column;
    background-image: none !important;
    padding: 0px !important;
    height: auto !important;
  }
  .EachItem2{
    background-size: 100% 100%;
    height: auto !important;
    background-image: none !important;
    display: flex;
    flex-direction: column;
  }
  /* .projectItem {
    background-image: url("../icons/productImage.png");
    background-size: 100% 300px;
    background-repeat: no-repeat;
    height: 300px;
  } */
  .HeaderDescription {
    font-size: 15px;
  }
  .approachTitle {
    font-size: 30px;
  }
  .approachDescription {
    font-size: 18px;
  }
}
@media (max-width: 300px) {
  .projectItem {
    /* background-image: url("../icons/productImage.png"); */
    background-size: 100% 300px;
    background-repeat: no-repeat;
    height: 300px;
  }
  .HeaderDescription {
    font-size: 15px;
  }
  .firstTitle {
    font-size: 12px;
    line-height: 18px;
  }
  .SecondTitle {
    font-size: 18px;
    line-height: 25px;
  }
  .Description {
    font-size: 10px;
    line-height: 12px;
  }
  .pageHeader {
    height: 350px;
  }
  .imageContainer {
    height: 300px;
  }
  .approachTitle {
    font-size: 30px;
  }
  .approachDescription {
    font-size: 18px;
  }
}
